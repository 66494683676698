import classNames from 'classnames'
import { forwardRef } from 'react'
import { Link, LinkProps } from '@remix-run/react'
import { focusRing } from '~/utils/styles'
import { Spinner } from '~/components/Button/Spinner'

type ButtonVariant = 'primary' | 'ghost' | 'text' | 'noPadding'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
  isLoading?: boolean
}

export const buttonClasses = {
  base: 'btn relative transition ease-in-out duration-150 flex items-center justify-center text-button1 rounded-lg',
  variants: {
    primary:
      'bg-blue-500 text-blue-900 hover:bg-blue-300 hover:shadow-ring-blue-1 px-2 py-2',
    ghost:
      'bg-blue-50 text-blue-900 hover:bg-marine-100 hover:shadow-ring-blue-1 px-2 py-2',
    text: 'hover:text-blue-500 px-2 py-2',
    noPadding: 'text-blue-900 hover:text-blue-500 disabled:py-1 disabled:px-2',
  },
  disabled:
    'disabled:bg-grey-200 disabled:text-grey-800 disabled:cursor-not-allowed disabled:shadow-none',
  loading:
    'bg-grey-100 cursor-not-allowed text-neutral-800 disabled:shadow-none hover:bg-grey-200 hover:shadow-none',
}

export const buttonIconClasses = {
  left: 'pe-1 w-8 h-auto flex-shrink-0',
  right: 'ps-1 w-8 h-auto flex-shrink-0',
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      isLoading,
      variant = 'primary',
      className,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(
          // base styles
          focusRing,
          buttonClasses.base,
          buttonClasses.disabled,
          // variant styles
          buttonClasses.variants[variant],
          // custom styles
          className,
          isLoading && buttonClasses.loading,
        )}
        type={type}
        aria-disabled={isLoading || !!props.disabled}
        aria-label={isLoading ? 'Bitte warten ...' : undefined}
        {...props}
      >
        {isLoading ? (
          <>
            <span className='absolute inset-0 flex items-center justify-center [&~*]:invisible'>
              <Spinner />
            </span>
            <span className='flex items-center opacity-0'>{children}</span>
          </>
        ) : (
          children
        )}
      </button>
    )
  },
)

Button.displayName = 'Button'

export interface ButtonLinkProps extends LinkProps {
  variant?: ButtonVariant
}

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ children, variant = 'primary', className, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        className={classNames(
          // base styles
          focusRing,
          buttonClasses.base,
          buttonClasses.disabled,
          // variant styles
          buttonClasses.variants[variant],
          // custom styles
          className,
        )}
        {...props}
      >
        {children}
      </Link>
    )
  },
)

ButtonLink.displayName = 'ButtonLink'
